import styled from "styled-components";

export const PageContainer = styled.div`
  width: 100%;
  padding: 20px 50px 50px 50px;
  font-family: 'Montserrat';

  @media only screen and (max-width: 700px) {
    padding: 20px 10px 10px 10px;
  }

  .customAlert > p {
    margin-bottom: 0;
  }

`;

export const Subcontainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  width: 100%;
  margin: auto;
  margin-top: 20px;
  gap: 20px;

  @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
`;

export const HomeHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  @media only screen and (max-width: 700px) {
    padding-left: 0px;
  }
`

export const UserAlertBadge = styled.div`
  background: #192619;
  margin: 0px;
`

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media only screen and (max-width: 700px) {
    width: 100%;
    padding: 0px;
    margin-top: 5px;
  }
`

export const MoneyChangeBadge = styled.span`
  padding: 5px;
  font-size: small;
  margin-left: 5px;
  background-color: ${props => props.pendingPayments >= 0 ? "#94E5AB" : "#A33"};
  color: ${props => props.pendingPayments >= 0 ? "#1C2D1C" : "white"};
`

export const ActivePicker = styled.div`
  width: fit-content;
  flex-wrap: nowrap !important;
  background: #1C2D1C;
  border-radius: 5px;

  @media only screen and (max-width: 700px) {
    width: 100%;
    margin: 0px;
    padding: 15px 10px 10px 15px;
  }

  li{
    flex-grow: 1;
  }

  > li > .nav-link{
    padding: 0.5rem 1rem;
  }
`

export const ActivePickerItem = styled.li`
  .active {
    background-color: #E9FD8C !important;
    color: #1d2d1d !important;
    font-weight: bold;
  }

  :hover {
    font-weight: bold;
    border-radius: 5px;
  }
`

export const ActivePickerItemLink = styled.a``

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #192619;
  padding: 4.5px;
  border-radius: 5px;
  padding-right: 10px;
`

export const NameSearchContainer = styled.div`
  width: 200px;

  input {
    background-color: #192619;
    border: 0px;
    color: white;

    ::placeholder {
      color: white;
      opacity: 0.6;
    }
  }
`;