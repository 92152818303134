import React, { useState, useEffect, useMemo, useCallback } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { checkIfTokenStillValid } from "../../repositories/utils";
import { NavBarMain, UserBox } from "..";
import { Form, Table } from "react-bootstrap";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, Brush, AreaChart, Area } from 'recharts';
import { useTranslation } from 'react-i18next';
import { motion } from "framer-motion"
import { Calendar } from 'lucide-react';
import {
  PageContainer,
  Subcontainer,
  HomeHeaderContainer,
  UserAlertBadge,
  ChartContainerParent,
  ChartContainer,
  ChartContainerHeader,
  UserContainer,
  TooltipContainer,
  TooltipLabel,
  TooltipItem,
  TooltipItemContainer,
  TooltipIcon,
  TooltipText,
  TooltipValue,
  TooltipSeparator,
  TooltipTotalContainer,
  TooltipTotal,
  TooltipTotalValue,
  Title,
  Value,
  DataMainContainer,
  DataContainer,
  DataContainerHeader,
  DataContainerIcon,
  DataContainerTitle,
  DateRangeContainer,
  DatePickerWrapper,
  DateFilterContainer,
  DateFilterButton,
} from './BotStats.styled';

import { useUsers } from "../../contexts/UsersContext";
import { useBilling } from "../../hooks";
import { ArrowBigUp, Sigma, PiggyBank, Percent } from 'lucide-react';
import { debounce } from 'lodash';

// Define colors for the pie chart
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF'];

// The component itself
export const BotStats = ({ history }) => {
  const [inputText, setInputText] = useState("");
  const [selectedUser, setSelectedUser] = useState("all");
  const [bookieData, setBookieData] = useState();
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [completeChartData, setCompleteChartData] = useState([]);
  const [fullChartData, setFullChartData] = useState([]);
  const [filteredChartData, setFilteredChartData] = useState([]);
  const [activeFilter, setActiveFilter] = useState('all');

  const { users } = useUsers();

  useEffect(() => {
    async function validateToken() {
      await checkIfTokenStillValid(history);
    }
    validateToken();
  }, []);

  const filterDataByDateRange = useCallback((data) => {
    if (startDate && endDate) {
      return data.filter(item => {
        const date = new Date(item.date);
        return date >= startDate && date <= endDate;
      });
    }
    return data;
  }, [startDate, endDate]);

  useEffect(() => {
    if (users) {
      const allProfitData = users.flatMap(user =>
        user.billing?.profitByDay?.map(day => ({
          date: `${day._id.year}-${String(day._id.month).padStart(2, '0')}-${String(day._id.day).padStart(2, '0')}`,
          profit: day.profit,
          expenditure: day.expenditure,
          email: day._id.email
        })) || []
      );

      let selectedData = selectedUser === "all" ? allProfitData : allProfitData.filter(data => data.email === selectedUser);

      const groupedData = selectedData.reduce((acc, curr) => {
        if (!acc[curr.date]) {
          acc[curr.date] = { date: curr.date, profit: 0, expenditure: 0 };
        }
        acc[curr.date].profit += curr.profit;
        acc[curr.date].expenditure += curr.expenditure;
        return acc;
      }, {});

      const sortedData = Object.values(groupedData).sort((a, b) => new Date(a.date) - new Date(b.date));

      // Calculate cumulative values
      let cumulativeProfit = 0;
      let cumulativeExpenditure = 0;
      const cumulativeData = sortedData.map(day => {
        cumulativeProfit += day.profit;
        cumulativeExpenditure += day.expenditure;
        return {
          ...day,
          cumulativeProfit: cumulativeProfit,
          cumulativeExpenditure: cumulativeExpenditure
        };
      });

      setCompleteChartData(cumulativeData);
      setFullChartData(cumulativeData);
      setFilteredChartData(cumulativeData);

      // Process bookie data
      const allBookieData = users.flatMap(user =>
        user.billing?.profitByDayAndBookie?.map(entry => ({
          name: entry._id.bookie,
          email: entry._id.email,
          profit: entry.profit,
          pending: entry.pending,
          expenditure: entry.expenditure,
          totalBets: entry.totalBets,
          date: new Date(entry._id.year, entry._id.month - 1, entry._id.day)
        })) || []
      );

      let filteredBookieData = selectedUser === "all" ? allBookieData : allBookieData.filter(data => data.email === selectedUser);
      
      // Apply date range filter
      filteredBookieData = filteredBookieData.filter(data => {
        const date = new Date(data.date);
        return (!startDate || date >= startDate) && (!endDate || date <= endDate);
      });

      // Aggregate bookie data
      const aggregatedBookieData = filteredBookieData.reduce((acc, curr) => {
        const existing = acc.find(item => item.name === curr.name);
        if (existing) {
          existing.profit += curr.profit;
          existing.pending += curr.pending;
          existing.expenditure += curr.expenditure;
          existing.totalBets += curr.totalBets;
        } else {
          acc.push({
            name: curr.name,
            profit: curr.profit,
            pending: curr.pending,
            expenditure: curr.expenditure,
            totalBets: curr.totalBets
          });
        }
        return acc;
      }, []);

      setBookieData(aggregatedBookieData);
    }
  }, [users, selectedUser, startDate, endDate]); // Add startDate and endDate to the dependency array

  useEffect(() => {
    if (completeChartData.length > 0) {
      const filtered = filterDataByDateRange(completeChartData);
      setFullChartData(filtered);
      setFilteredChartData(filtered);
    }
  }, [completeChartData, startDate, endDate, activeFilter]);

  const totalProfit = useMemo(() => {
    return filteredChartData.reduce((acc, day) => acc + day.profit, 0);
  }, [filteredChartData]);

  const totalBets = useMemo(() => {
    return bookieData?.reduce((acc, bookie) => acc + bookie.totalBets, 0) || 0;
  }, [bookieData]);

  const totalExpenditure = useMemo(() => {
    return filteredChartData.reduce((acc, day) => acc + day.expenditure, 0);
  }, [filteredChartData]);

  const totalYield = useMemo(() => {
    return totalExpenditure !== 0 ? (totalProfit / totalExpenditure) * 100 : 0;
  }, [totalProfit, totalExpenditure]);

  // Debounced function to update date range
  const debouncedUpdateDateRange = useCallback(
    debounce((startIndex, endIndex) => {
      if (completeChartData.length > 0) {
        const newStartDate = new Date(completeChartData[startIndex].date);
        const newEndDate = new Date(completeChartData[endIndex].date);
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        setActiveFilter('custom');
      }
    }, 300),
    [completeChartData]
  );

  // Update the handleBrushChange function
  const handleBrushChange = useCallback((brushArea) => {
    if (brushArea && brushArea.startIndex !== undefined && brushArea.endIndex !== undefined) {
      debouncedUpdateDateRange(brushArea.startIndex, brushArea.endIndex);
    }
  }, [debouncedUpdateDateRange]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <TooltipContainer>
          <TooltipLabel>{new Date(label).toLocaleDateString()}</TooltipLabel>
          {payload.map((pld) => (
            <TooltipItem key={pld.dataKey}>
              <TooltipItemContainer>
                <TooltipIcon color={pld.dataKey === "cumulativeProfit" ? "#94E5AB" : "#B8DCEF"} />
                <TooltipText>{t(pld.dataKey)}</TooltipText>
              </TooltipItemContainer>
              <TooltipValue style={{ color: pld.dataKey === "cumulativeProfit" ? "#94E5AB" : "#B8DCEF" }}>{pld.payload[pld.dataKey === "cumulativeProfit" ? "profit" : "expenditure"].toFixed(2)}€</TooltipValue>
            </TooltipItem>
          ))}
          <TooltipSeparator />
          <TooltipTotalContainer>
            <TooltipTotal>{t('totalProfit')}</TooltipTotal>
            <TooltipTotalValue style={{ color: "#94E5AB" }}>{payload.reduce((acc, pld) => acc + (pld.dataKey === "cumulativeProfit" ? pld.payload.cumulativeProfit : 0), 0).toFixed(2)}€</TooltipTotalValue>
          </TooltipTotalContainer>
          <TooltipTotalContainer>
            <TooltipTotal>{t('totalExpenditure')}</TooltipTotal>
            <TooltipValue>{payload.reduce((acc, pld) => acc + (pld.dataKey === "cumulativeExpenditure" ? pld.payload.cumulativeExpenditure : 0), 0).toFixed(2)}€</TooltipValue>
          </TooltipTotalContainer>
        </TooltipContainer>
      );
    }

    return null;
  };

  // Add this new function near the top of your component, after the state declarations
  const getDateIndex = useCallback((date) => {
    if (!date || completeChartData.length === 0) return undefined;
    const index = completeChartData.findIndex(item => new Date(item.date) >= date);
    return index === -1 ? completeChartData.length - 1 : index;
  }, [completeChartData]);

  // Update the handleDateFilter function
  const handleDateFilter = (filter) => {
    setActiveFilter(filter);
    const today = new Date();
    let newStartDate, newEndDate;

    switch (filter) {
      case '1d':
        newStartDate = new Date(today.setDate(today.getDate() - 1));
        newEndDate = new Date();
        break;
      case '7d':
        newStartDate = new Date(today.setDate(today.getDate() - 7));
        newEndDate = new Date();
        break;
      case '1m':
        newStartDate = new Date(today.setMonth(today.getMonth() - 1));
        newEndDate = new Date();
        break;
      case '3m':
        newStartDate = new Date(today.setMonth(today.getMonth() - 3));
        newEndDate = new Date();
        break;
      case '1y':
        newStartDate = new Date(today.setFullYear(today.getFullYear() - 1));
        newEndDate = new Date();
        break;
      case 'all':
      default:
        newStartDate = null;
        newEndDate = null;
        break;
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  return (
    <PageContainer>
      <NavBarMain currentPage="bot" type="stats" history={history} />
      {bookieData && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
        <Subcontainer>
          <HomeHeaderContainer>
            <UserAlertBadge className="alert" bgColor={"black"}>
              <i className="fas fa-user" /> {t('users')} <b>{users?.length}</b>
            </UserAlertBadge>
            <UserContainer>
              <Form.Label style={{ marginBottom: 0 }}><i className="fa-solid fa-user" /></Form.Label>
              <Form.Control
                as="select"
                label="Users"
                name="users"
                onChange={({ target }) => setSelectedUser(target.value)}
                value={selectedUser}
                style={{ display: "inline", width: "200px", height: 33.25, marginBottom: 0 }}
              >
                <option value="all">{t('allUsers')}</option>
                {users?.map(user => (
                  <option key={user._id.email} value={user._id.email}>{user._id.email}</option>
                ))}
              </Form.Control>
            </UserContainer>
            <DateRangeContainer>
              <Calendar size={20} style={{ marginLeft: 5 }}/>
              <DateFilterContainer>
                <DateFilterButton active={activeFilter === '1d'} onClick={() => handleDateFilter('1d')}>1D</DateFilterButton>
                <DateFilterButton active={activeFilter === '7d'} onClick={() => handleDateFilter('7d')}>7D</DateFilterButton>
                <DateFilterButton active={activeFilter === '1m'} onClick={() => handleDateFilter('1m')}>1M</DateFilterButton>
                <DateFilterButton active={activeFilter === '3m'} onClick={() => handleDateFilter('3m')}>3M</DateFilterButton>
                <DateFilterButton active={activeFilter === '1y'} onClick={() => handleDateFilter('1y')}>1Y</DateFilterButton>
                <DateFilterButton active={activeFilter === 'all'} onClick={() => handleDateFilter('all')}>All</DateFilterButton>
              </DateFilterContainer>
              <DatePickerWrapper>
                <DatePicker
                  selected={startDate}
                  onChange={date => {
                    setStartDate(date);
                    setActiveFilter('custom');
                  }}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText={t('startDate')}
                  calendarStartDay={1}
                  dateFormat="dd/MM/yyyy"
                />
              </DatePickerWrapper>
              <DatePickerWrapper>
                <DatePicker
                  selected={endDate}
                  onChange={date => {
                    setEndDate(date);
                    setActiveFilter('custom');
                  }}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  placeholderText={t('endDate')}
                  calendarStartDay={1}
                  dateFormat="dd/MM/yyyy"
                />
              </DatePickerWrapper>
            </DateRangeContainer>
          </HomeHeaderContainer>
          <DataMainContainer>
            <DataContainer>
              <DataContainerHeader>
                <DataContainerTitle>{t('profit')}</DataContainerTitle>
                <DataContainerIcon style={{ color: "#94E5AB" }}>
                  <ArrowBigUp size={34}/>
                </DataContainerIcon>
              </DataContainerHeader>
              <Value style={{ color: "#94E5AB" }}>{totalProfit.toFixed(2)}€</Value>
            </DataContainer>
            <DataContainer>
              <DataContainerHeader>
                <DataContainerTitle>{t('totalBets')}</DataContainerTitle>
                <DataContainerIcon style={{ color: "#E9FD8C" }}>
                  <Sigma size={34}/>
                </DataContainerIcon>
              </DataContainerHeader>
              <Value style={{ color: "#E9FD8C" }}>{totalBets}</Value>
            </DataContainer>
            <DataContainer>
              <DataContainerHeader>
                <DataContainerTitle>{t('expenditure')}</DataContainerTitle>
                <DataContainerIcon style={{ color: "#B8DCEF" }}>
                  <PiggyBank size={34} />
                </DataContainerIcon>
              </DataContainerHeader>
              <Value style={{ color: "#B8DCEF" }}>{Math.abs(totalExpenditure)}€</Value>
            </DataContainer>
            <DataContainer>
              <DataContainerHeader>
                <DataContainerTitle>Yield</DataContainerTitle>
                <DataContainerIcon style={{ color: "#C084FC" }}>
                  <Percent size={34}/>
                </DataContainerIcon>
              </DataContainerHeader>
              <Value style={{ color: "#C084FC" }}>{totalYield.toFixed(2)}%</Value>
            </DataContainer>
          </DataMainContainer>
          <ChartContainerParent>
            <ChartContainer>
              <ChartContainerHeader>
                <Title>{t('profit')}</Title>
              </ChartContainerHeader>
              <ResponsiveContainer width="100%" height={550}>
                <LineChart data={completeChartData} margin={{ top: 20, bottom: 70 }}>
                  <CartesianGrid strokeDasharray="3 0" vertical={false} stroke="rgba(255, 255, 255, 0.1)"/>
                  <XAxis 
                    dataKey="date" 
                    stroke="white" 
                    axisLine={false} 
                    tickLine={false} 
                    tickMargin={5} 
                    angle={-45} 
                    textAnchor="end"
                    tickFormatter={(date) => {
                      const [, month, day] = date.split('-');
                      return `${day}/${month}`;
                    }}
                    interval="preserveStartEnd"
                    minTickGap={40}
                    height={60}
                  />
                  <YAxis yAxisId="left" stroke="white" axisLine={false} tickLine={false} tickMargin={5} />
                  <YAxis yAxisId="right" orientation="right" stroke="white" axisLine={false} tickLine={false} tickMargin={5} />
                  <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
                  <Line yAxisId="left" type="monotone" dataKey="cumulativeProfit" name="Cumulative Profit" stroke="#94E5AB" dot={false} strokeWidth={3} />
                  <Line yAxisId="right" type="monotone" dataKey="cumulativeExpenditure" name="Cumulative Expenditure" stroke="#B8DCEF" dot={false} strokeWidth={3} />
                  <Brush
                    dataKey="date"
                    height={40}
                    stroke="#758b77"
                    fill="#192619"
                    onChange={handleBrushChange}
                    startIndex={getDateIndex(startDate)}
                    endIndex={getDateIndex(endDate)}
                    y={470}
                    tickFormatter={(date) => {
                      const [year, month, day] = date.split('-');
                      return new Date(year, month - 1, day).toLocaleDateString();
                    }}
                  >
                    <AreaChart>
                      <CartesianGrid strokeDasharray="3 0" vertical={false} stroke="rgba(255, 255, 255, 0.1)"/>
                      <YAxis hide domain={['auto', 'auto']} />
                      <Area dataKey="cumulativeProfit" stroke="#94E5AB" fill="#94E5AB" dot={false} />
                    </AreaChart>
                  </Brush>
                </LineChart>
              </ResponsiveContainer>
            </ChartContainer>
          </ChartContainerParent>
          <ChartContainer style={{ overflow: 'scroll', width: '100%' }}>
            <ChartContainerHeader>
              <Title>Bookies</Title>
            </ChartContainerHeader>
            <Table hover>
              <thead>
                <tr>
                  <th>Bookie</th>
                  <th>{t('profit')}</th>
                  <th>{t('totalPending')}</th>
                  <th>{t('expenditure')}</th>
                  <th>{t('totalBets')}</th>
                </tr>
              </thead>
              <tbody>
                {bookieData
                  ?.sort((a, b) => b.totalBets - a.totalBets)
                  .map((bookie, index) => (
                    <tr key={index} style={{ backgroundColor: index % 2 ? '#111E12' : '#1d2d1d' }}>
                      <td>{bookie.name}</td>
                      <td>{bookie.profit.toFixed(2)}€</td>
                      <td>{bookie.pending.toFixed(2)}€</td>
                      <td>{bookie.expenditure.toFixed(2)}€</td>
                      <td>{bookie.totalBets}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </ChartContainer>
        </Subcontainer>
      </motion.div> 
      )}
    </PageContainer>
  );
};