import React, { useCallback, useState, useEffect, useRef } from "react";
import { motion } from "framer-motion"; // Import Framer Motion
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import "./Card.styled";
import { useUser } from "../../hooks";
import { useUsers } from "../../contexts/UsersContext";
import {
  PopOverBookie,
  PopoverGrid,
  PopoverGridItem,
  PopoverGridItemHeader,
  PopoverGridItemContent
} from "./Card.styled";
import { withRouter } from "react-router-dom";
import { MoreVerticalIcon, Pencil, Play, StopCircle, EyeIcon, CheckCircle2, XCircle, History, RefreshCw, Clock, Info, Unlink } from "lucide-react"
import { useTranslation } from "react-i18next";

const Card = ({ account, index, history, email, name, enabled, bookie, isAdmin, status }) => {
  const { enableBookie } = useUsers();
  const { getBookieCredentials, detachProxy } = useUser()
  const [credentials, setCredentials] = useState({});
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [showDropdown, setShowDropdown] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const editBookieInformation = () => history.push(`/bookies/${email}/${name}/edit`);
  const changeBookieStatus = useCallback(
    async () => await enableBookie(email, name, enabled),
    [email, enableBookie, enabled, name],
  );

  const checkIfErrorShouldBeShown = () =>
    isAdmin ? true : ["NewMessageError", "CredentialsError", "Error"].includes(bookie.loginError);

  const getErrorMessage = () => {
    const loginError = (bookie || {}).loginError;
    const messagesByType = {
      NewMessageError: t("newMessageError"),
      LoginError: t("loginError"),
      StatusCodeError: t("statusCodeError"),
      CredentialsError: t("credentialsError"),
      default: t("loginError"),
    };
    return messagesByType[loginError] || messagesByType.default;
  };

  const handleViewCredentials = async () => {
    const creds = await getBookieCredentials(email, name);
    setCredentials(creds);
    setShow(true);
  };

  const handleDetachProxy = async () => {
    const body = {
      email,
      username: account.username,
      bookie: name
    }
    const response = await detachProxy(body);
    console.log(response);
  };

  return (
    <motion.div
      className={`bg-[#2A3C29] shadow-md rounded-xl`}
      animate={{ opacity: account.enabled || showDropdown ? 1 : 0.5 }} // Animate opacity
    >
      <div className="bg-[#3A4C39] p-3 flex flex-col rounded-xl">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <h3 className="text-[#E8FC8B] text-lg font-semibold capitalize">{account.name}</h3>
            <OverlayTrigger
              trigger="click"
              key="top"
              placement="bottom"
              overlay={
                <PopOverBookie id={`popover-positioned-bottom`}>
                  <PopOverBookie.Title as="h3">
                    {t("accountStatus")}
                  </PopOverBookie.Title>
                  <PopOverBookie.Content>
                    <PopoverGrid>
                      <PopoverGridItem>
                        <PopoverGridItemHeader>
                          {status?.logged ? (
                            <CheckCircle2 size={18} color="#94E5AB" />
                          ) : (
                            <XCircle size={18} color="#A33" />
                          )}
                          {t("logged")}
                        </PopoverGridItemHeader>
                        <PopoverGridItemContent>
                          {status?.logged ? t("yes") : t("no")}
                        </PopoverGridItemContent>
                      </PopoverGridItem>
                      {status?.lastLoginUpdate &&
                        <PopoverGridItem>
                          <PopoverGridItemHeader>
                            <Clock size={18} color="#B8DCEF" />
                            {t("lastUpdate")}
                          </PopoverGridItemHeader>
                          <PopoverGridItemContent>
                            {new Date(status?.lastLoginUpdate).toLocaleString()}
                          </PopoverGridItemContent>
                        </PopoverGridItem>
                      }{!!status?.logInRetries &&
                        <PopoverGridItem>
                          <PopoverGridItemHeader>
                            <RefreshCw size={18} color="#FF9800" />
                            {t("loginRetries")}
                          </PopoverGridItemHeader>
                          <PopoverGridItemContent>
                            {status?.logInRetries}
                          </PopoverGridItemContent>
                        </PopoverGridItem>
                      }
                      {status?.lastLogged && new Date(status.lastLogged) > new Date(Date.now() - 30 * 24 * 60 * 60 * 1000) && (
                        <PopoverGridItem>
                          <PopoverGridItemHeader>
                            <History size={18} color="#9C27B0" />
                            {t("lastLogin")}
                          </PopoverGridItemHeader>
                          <PopoverGridItemContent>
                            {new Date(status.lastLogged).toLocaleString()}
                          </PopoverGridItemContent>
                        </PopoverGridItem>
                      )}
                    </PopoverGrid>
                  </PopOverBookie.Content>
                </PopOverBookie>
              }
            >
              <button className="ml-1 bg-[#3A4C39] text-[#ABCDDF] hover:bg-[#4A5C49] hover:text-[#E8FC8B] p-2 rounded-md">
                <Info className="h-4 w-4" />
              </button>
            </OverlayTrigger>
            {account?.limits?.hard && (
              <span className={`px-2 py-1 rounded-full text-xs font-semibold text-white bg-orange-500`}>
                {t("limited")}
              </span>
            )}
          </div>
          <div className="relative">
            <button
              onClick={() => setShowDropdown(showDropdown === index ? null : index)}
              className="h-8 w-8 p-0 text-[#ABCDDF] hover:bg-[#ABCDDF]/10 rounded-full flex items-center justify-center"
            >
              <MoreVerticalIcon className="h-4 w-4" />
            </button>
            {showDropdown === index && (
              <div ref={dropdownRef} className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-[#2A3C29] ring-1 ring-black ring-opacity-5 z-50">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <button
                    className="block w-full text-left px-4 py-2 text-sm text-[#ABCDDF] hover:bg-[#3A4C39] hover:text-[#E8FC8B]"
                    onClick={() => changeBookieStatus()}
                  >
                    {enabled ? <StopCircle className="inline-block mr-2 h-4 w-4" /> : <Play className="inline-block mr-2 h-4 w-4" />}
                    {enabled ? t("disableAccount") : t("enableAccount")}
                  </button>
                  <button
                    className="block w-full text-left px-4 py-2 text-sm text-[#ABCDDF] hover:bg-[#3A4C39] hover:text-[#E8FC8B]"
                    onClick={() => editBookieInformation()}
                  >
                    <Pencil className="inline-block mr-2 h-4 w-4" />
                    {t("editAccount")}
                  </button>
                  {isAdmin && (
                    <button
                      className="block w-full text-left px-4 py-2 text-sm text-[#ABCDDF] hover:bg-[#3A4C39] hover:text-[#E8FC8B]"
                      onClick={handleViewCredentials}
                    >
                      <EyeIcon className="inline-block mr-2 h-4 w-4" />
                      {t("viewCredentials")}
                    </button>
                  )}
                  {isAdmin && (
                    <button
                      className="block w-full text-left px-4 py-2 text-sm text-[#ABCDDF] hover:bg-[#3A4C39] hover:text-[#E8FC8B]"
                      onClick={handleDetachProxy}
                    >
                      <Unlink className="inline-block mr-2 h-4 w-4" />
                      {t("detachProxy")}
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <p className="text-white/70 text-sm mt-1">{account.username}</p>
      </div>
      {(account.balance || account.needsDeposit || account.loginError) && (
        <div className="p-3">
          {account.balance?.total != null && (
            <div className="flex justify-between items-center mb-2">
              <span className="text-white font-semibold">Total:</span>
              <span className="text-[#E8FC8B] text-xl font-bold">{account.balance.total}€</span>
            </div>
          )}
          {account.balance?.normal != null && (
            <div className="flex justify-between items-center text-sm">
              <span className="text-white/70">Normal:</span>
              <span className="text-white">{account.balance.normal}€</span>
            </div>
          )}
          {account.balance?.freebets != null && account.balance.freebets !== 0 && (
            <div className="flex justify-between items-center text-sm mt-1">
              <span className="text-white/70">Freebets:</span>
              <span className="text-white">{account.balance.freebets}€</span>
            </div>
          )}
          {account?.needsDeposit && (
            <div className="mt-2 p-2 rounded text-sm bg-yellow-900 text-yellow-100">
              {t("attentionDeposit")}
            </div>
          )}
          {account?.loginError && (
            <div className="mt-2 p-2 rounded text-sm bg-red-900 text-red-100">
              {getErrorMessage()}
            </div>
          )}
        </div>
      )}
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Credenciales</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: "flex", flexDirection: "column" }}>
          <p>Usuario: {credentials.username}</p>
          <p>Password: {credentials.password}</p>
        </Modal.Body>
      </Modal>

    </motion.div>
  );
};

export default withRouter(Card);